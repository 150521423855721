<template>
  <div class="cell-container">
    <div class="label">{{label}}</div>
    <div class="value" v-if="value">{{value}}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      value: ''
    },
    value: {
      type: String,
      value: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-container {
  display: flex;
  flex: 1;
  align-items: center;
  .label {
    width: 80px;
    height: 31px;
    line-height: 31px;
    background: #EAEAEA;
    font-size: 12px;
    text-align: center;
    color: #818181;
    flex-shrink: 0;
  }
  .value {
    background: white;
    font-size: 14px;
    color: #818181;
    flex: 1;
  }
}
</style>