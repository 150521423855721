<template>
  <div class="invoice-info-page">
    <Header />
    <div class="card-container">
      <Card class="card" v-for="(item, index) in list" :key="index" :info="item" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Card from './components/invoiceTabelCard.vue'
import { fetchInvoiceInfo } from '@/api/info'

export default {
  components: {
    Header,
    Card
  },
  data() {
    return {
      id: '',
      list: []
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    let response = await fetchInvoiceInfo({
      "sapOrderCode": this.id|| "0000771822",
    })
    this.list = response.data.listInvoice
  }
}
</script>

<style lang="scss" scoped>
.invoice-info-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .card-container {
    padding-top: 12px;
    .card {
      margin-bottom: 12px;
    }
  }
}
</style>