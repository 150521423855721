<template>
  <div class="container">
    <div class="card-container">
      <div class="row">
        <Cell label="Spa订单号" :value="info.vbelv" />
      </div>
      <div class="row">
        <Cell label="电子发票号" :value="info.oricode" />
      </div>
      <div class="row">
        <Cell label="发票类型" :value="info.ztypen" />
        <Cell label="发票日期" :value="info.erdat" />
      </div>
      <div class="row">
        <Cell label="发票预览">
          <div class="icon-container">
            <img :src="CheckIcon" class="check-icon" @click="handleToCheck(info.invoiceurl)" />
          </div>
        </Cell>
        <Cell label="Pdf下载">
          <div class="icon-container">
            <img :src="DownloadIcon" class="check-icon" @click="handleToDownload(info.pdfurl)" />
          </div>
        </Cell>
      </div>
    </div>
  </div>
</template>

<script>
import Cell from './cell.vue'
import CheckIcon from '@/assets/images/common/check-icon.png'
import DownloadIcon from '@/assets/images/common/download-icon.png'

export default {
  components: {
    Cell,
  },
  props: {
    info: {
      type: Object,
      default: ()=>{
        return {
          orderNumber: '',
          invoiceNumber: '',
          type: '',
          date: '',
          pdfsrc: ''
        }
      }
    }
  },
  data() {
    return {
      CheckIcon,
      DownloadIcon
    }
  },
  methods: {
    handleToDownload(url) {
      this.$router.push({
        path: `/order/download?fileUrl=${encodeURIComponent(url)}`,
      })
    },
    handleToCheck(url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 12px;
  .card-container {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    opacity: 1;
    overflow: hidden; 
    border-radius: 10px;
    .row {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 1px;
      }
      .icon-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .check-icon {
          width: 21px;
          // height: 16px;
        }
        .download-icon {
          width: 17px;
          // height: 15px;
        }
        .a-link {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
</style>