<template>
  <div class="common-table">
    <div class="theader">
      <div class="th" v-for="col in computedColumns" :key="col.props">
        {{col.name}}
      </div>
    </div>
    <div class="tbody" :style="{'margin-top': layer ? '12px' : '0'}">
      <!--<div class="tr" v-for="(row, idx) in data" :key="idx">
        <div class="td" v-for="col in columns.slice(0,4)" :key="col.props + idx">
          {{row[col.props]}}
        </div>
        <div class="td delete" >
          删除
        </div>
      </div>-->
      <div class="tr" v-for="(col2, index)  in data" :key="index">
        <div class="td">
          <Field
                  readonly
                  class="field"
                  style="width: 65px;"
                  placeholder="请选择"
                  v-model="col2.brandName"
                  @click="showBrand = true;selectIndex = index"
          />
          <Popup v-model="showBrand" position="bottom">
            <Picker
                    show-toolbar
                    ref="pickerRef"
                    :columns="columnsBrand"
                    value-key="dictLabel"
                    @confirm="onConfirmBrand"
                    @cancel="showBrand = false"
            />
          </Popup>
        </div>
        <div class="td">
          <Field
                  readonly
                  class="field"
                  style="width: 80px;"
                  placeholder="请选择"
                  v-model="col2.technologyName"
                  @click="showTechnology = true;selectIndex = index"
          />
          <Popup v-model="showTechnology" position="bottom">
            <Picker
                    show-toolbar
                    :columns="columnsTechnology"
                    value-key="dictLabel"
                    @confirm="onConfirmTechnology"
                    @cancel="showTechnology = false"
            />
          </Popup>
        </div>
        <div class="td">
          <Field
                  class="field"
                  v-model="col2.matchEq"
          >
          </Field>
        </div>
        <div class="td">
          <Field
                  class="field"
                  v-model="col2.stockVolume"
          >
          </Field>
        </div>
        <div class="td delete" @click="deleteaa(col2)">
          删除
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field,Popup,Picker } from "vant";
export default {
  components: {
    Field,
    Popup,
    Picker,
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => [],
    },
    columnsBrand: {
      type: Array,
      default: () => [],
    },
    columnsTechnology: {
      type: Array,
      default: () => [],
    },
    layer: {
      type: Boolean,
      default: false
    },
    showOp: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedColumns() {
      if (!this.showOp) {
        return this.columns.slice(0, 4)
      }
      else {
        return this.columns
      }
    }
  },
  data() {
    return {
      inputValue: {
        technologyName: '',
        matchEq: '',
        brandName: '',
        stockVolume: ''
      },
      showBrand: false,
      showTechnology: false,
      selectIndex: 0,
    }
  },
  watch: {
    inputValue: {
      handler() {
      },
      deep: true
    }
  },
  methods: {
    handleAdd() {
      this.$emit('add', this.inputValue)
    },
    deleteaa(item) {
      console.log(111);
      this.data.splice(this.data.indexOf(item),1);
    },
    onConfirmBrand(value) {
      this.data[this.selectIndex].brandName = value.dictLabel;
      this.data[this.selectIndex].brandId = value.dictValue;
      this.showBrand = false;
    },
    onConfirmTechnology(value) {
      this.data[this.selectIndex].technologyName = value.dictLabel;
      this.data[this.selectIndex].technology = value.dictValue;
      this.showTechnology = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.common-table {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2C2C2C;
  .theader {
    background-color: #fff;
    display: flex;
    align-items: center;
    min-height: 44px;
    line-height: 20px;
    // line-height: 44px;
    .th {
      flex: 1;
    }
  }
  .tr {
    background-color: #fff;
    display: flex;
    align-items: center;
    line-height: 32px;
    .td {
      display: flex;
      flex: 1;
      justify-content: center;
      .field {
        flex-shrink: 0;
        width: 45px;
        height: 25px;
        background: #ffffff;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 5px;
        padding: 2px 8px;
        ::v-deep .van-field__body {
          height: 100%;
        }
        ::v-deep .van-field__control {
          text-align: center;
        }
      }
    }
    .delete {
      color: #CD001F;
    }
  }
}
</style>