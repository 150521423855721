<template>
  <div class="store-info-page">
    <Header :tabs="[{title: '本品库存'}, {title: '竞品库存'}]" :activeName="activeName" @click="handleTabClick" >
       <img :src="AddIcon" class="add-icon" @click="handleAddStore" />
    </Header>
    <div class="recorder-date-container">
      录入时间：{{date}}
    </div>
    <CustomTable :columns="columns" :data="data" :columnsBrand="columnsBrand" :columnsTechnology="columnsTechnology" :layer="true" :showOp="showOp" @add="handleAdd" />
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
    <div class="btn-container">
      <CustomButton size="large" @click="save">保存</CustomButton>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerTabs/index.vue'
import AddIcon from '@/assets/images/common/add.png'
import CustomButton from '@/components/common/button/CustomButton.vue';
import CustomTable from '@/components/table/editTable.vue'
import { fetchStockList, saveStoreInfo } from '@/api/info'
import { fetchDictList } from '@/api/select'
import { Loading } from 'vant'

export default {
  components: {
    Header,
    CustomTable,
    CustomButton,
    Loading
  },
  async beforeMount() {
    this.id = this.$route.query.id
    this.fetchList()
  },
  data() {
    return {
      AddIcon,
      activeName: '本品库存',
      date: ' ',
      isLoading: false,
      columns: [
        { name: '品牌', props: 'brandName' },
        { name: '工艺', props: 'technologyName' },
        { name: '配比', props: 'matchEq' },
        { name: '库存(吨)', props: 'stockVolume' },
        { name: '操作', props: 's' },
      ],
      data: [],
      showOp: true,
      columnsBrand: [],
      columnsTechnology: [],
    }
  },
  async created() {
    let dictTechnology = await fetchDictList({
      type: 'eb_material_class'
    });
    this.columnsTechnology = dictTechnology.data;
  },
  methods: {
    handleTabClick(tab) {
      this.activeName = tab
      this.data = []
      this.fetchList()
    },
    async fetchList() {
      this.isLoading = true
      try {
        let response = await fetchStockList({
          "kjTerminalNodeId": this.id || "4a3b41f030774f6985c664d63fe43b69",
          "stockType": this.activeName === '本品库存' ? '1' : '2',
        })  
        this.data = response.data

        let dictBrand = await fetchDictList({
          type: this.activeName === '本品库存' ? 'crm_brand' : 'crm_compete_brand'
        });
        this.columnsBrand = dictBrand.data;

        this.isLoading = false
      }
      catch(err) {
        this.$toast(err.message)
        this.isLoading = false
      }
    },
    handleAddStore() {
      /*this.showOp = !this.showOp;*/
      /*this.data.push({
        "brandName": "",
        "technologyName": "",
        "matchEq": "",
        "stockVolume": ""
      });*/
      this.data.push({});
    },
    async save() {
      var stockList = [];
      this.data.forEach(stock => {
        stockList.push({
          "matchEq":!stock.matchEq?"":stock.matchEq,
          "brandId":!stock.brandId?"":stock.brandId,
          "stockVolume":!stock.stockVolume?"":stock.stockVolume,
          "technology":!stock.technology?"":stock.technology
        });
      });

      try {
        await saveStoreInfo({
          "kjTerminalNodeId": this.id || "4a3b41f030774f6985c664d63fe43b69",
          "stockType": this.activeName === '本品库存' ? '1' : '2',
          "stockList": stockList,
        })
        this.$toast('添加成功')
        this.data = []
        this.fetchList()
      }
      catch (err) {
        this.$toast(err.message)
      }

    },
    async handleAdd(inputValue) {
      if (inputValue.brandName === '' || inputValue.matchEq === '' || inputValue.matchEq === '' || inputValue.stockVolume === '') {
        this.$toast('请完整输入竞品信息')
        return
      }
      try {
        await saveStoreInfo({
          "kjTerminalNodeId": this.id || "4a3b41f030774f6985c664d63fe43b69",
          "stockType": this.activeName === '本品库存' ? '1' : '2', 
          "stockList": [inputValue],
        })
        this.$toast('添加成功')
        this.showOp = false
        this.data = []
        this.fetchList()
      }
      catch (err) {
        this.$toast(err.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.store-info-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .add-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 24px;
  }
  .recorder-date-container {
    width: 100%;
    height: 33;
    text-align: left;
    box-sizing: border-box;
    padding: 9px 0 0 24px;
    font-size: 12px;
    color: #CECECE;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
  .btn-container {
    position:fixed;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px ;
  }
}
</style>