<template>
  <div class="container">
    <div class="label">{{label}}</div>
    <div class="balance">¥{{balance}}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    balance: {
      type: String,
      default: '0'
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px;
  font-size: 14px;
  background: white;
  .label {
    color: #91939A;
  }
  .balance {
    color: #2C2C2C;
  }
}
</style>