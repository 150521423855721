<template>
  <div class="container">
    <DatePicker v-model="startDate" />
    ~
    <DatePicker v-model="endDate" />
  </div>  
</template>

<script>
import DatePicker from '@/components/select/datePicker.vue'
import dayjs from 'dayjs'

export default {
  props: {
    value: {}
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      startDate: {},
      endDate: {}
    }
  },
  beforeMount() {
    this.startDate = new Date(`${dayjs(new Date()).format('YYYY')}-01-01`)
    this.endDate = new Date(dayjs(new Date()).format('YYYY-MM-DD'))
  },
  watch: {
    startDate(newVal) {
      if (newVal.getTime() > this.endDate.getTime()) {
        this.endDate = newVal
      }
      this.$emit('changeStart', {date: this.startDate})
    },
    endDate() {
      this.$emit('changeEnd', {date: this.endDate})
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 44px;
  display: flex;
  box-sizing: border-box;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #CECECE;
  background: white;
}
</style>