<template>
  <div class="account-balance-page">
    <Header />
    <Form class="form" :rows="rows" v-model="form" />
    <BalanceItem v-for="(item, index) in list" :key="index" :balance="info[item.field] || '0'" :label="item.label" />
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Form from '@/components/common/form/index.vue'
import BalanceItem from './components/balanceItem.vue'
import { fetchBalance } from '@/api/home'
import dayjs from 'dayjs'
import { Loading } from 'vant'

export default {
  components: {
    Header,
    Form,
    BalanceItem,
    Loading
  },
  data() {
    return {
      form: {
        cloingDay: new Date()
      },
      info: {},
      rows: [
        {label: '截止日期', key: 'cloingDay', type: 'date'}
      ],
      endDate: dayjs(new Date()).format('YYYYMMDD'),
      isLoading: false,
      list: [
        {
          label: '保证金金额',
          field: 'zbzj'
        },
        {
          label: '可用余额',
          field: 'kyyue'
        },
        {
          label: '订单占用金额',
          field: 'zyyue'
        },
        {
          label: '保证金标准',
          field: 'zbzjbz'
        },
        {
          label: '余额',
          field: 'cwyue'
        },
        {
          label: '可用返利',
          field: 'backPrice'
        },
        {
          label: '未结算费用',
          field: 'tranClosePrice'
        },
      ]
    }
  },
  watch: {
    form: {
      handler(newVal) {
        let retDate = dayjs(newVal.cloingDay).format('YYYYMMDD')
        if (retDate !== this.endDate) {
          this.endDate = retDate
          this.fetchBalanceInfo()
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.fetchBalanceInfo()
  },
  methods: {
    async fetchBalanceInfo() {
      let data = {
        "dealerId": "1000443827",
        "endDate": this.endDate
      }
      this.isLoading = true
      let response = await fetchBalance(data)
      this.isLoading = false
      this.info = response.data
    }
  }
}
</script>

<style lang="scss" scoped>
.account-balance-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .form {
    margin-bottom: 12px;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>